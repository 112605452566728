export function formatFileSize(size: number) {
  if (size < 1024) {
    return size + ' bytes';
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  } else {
    return (size / (1024 * 1024)).toFixed(2) + ' MB';
  }
}

export async function compressImage(
  file: File,
  maxWidth = 1024,
  quality = 0.7,
) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx: any = canvas.getContext('2d');

      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height = Math.round((height *= maxWidth / width));
        width = maxWidth;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob: any) => {
          const compressedFile = new File([blob], file.name, {
            type: file.type,
          });
          resolve(compressedFile);
        },
        file.type,
        quality,
      );
    };
    img.onerror = reject;
  });
}

// 新版的压缩图片方案
export async function compressImageAlternative(
  file: File,
  maxWidth = 1024,
  quality = 0.9,
  targetType = 'image/jpeg', // 输出目标格式，默认使用 JPEG
) {
  try {
    // 使用 createImageBitmap 生成更高效的位图
    const imgBitmap = await createImageBitmap(file);

    let width = imgBitmap.width;
    let height = imgBitmap.height;

    // 计算新的尺寸
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }

    // 创建 OffscreenCanvas，如果浏览器不支持，则回退到普通 Canvas
    const isOffscreenCanvasSupported = 'OffscreenCanvas' in window;
    const canvas = isOffscreenCanvasSupported
      ? new OffscreenCanvas(width, height)
      : document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    // 绘制图像到 Canvas
    ctx.drawImage(imgBitmap, 0, 0, width, height);

    // 转换为 Blob
    let blob: Blob | null;
    if (isOffscreenCanvasSupported && canvas instanceof OffscreenCanvas) {
      blob = await canvas.convertToBlob({
        type: targetType,
        quality,
      });
    } else {
      blob = await new Promise<Blob | null>((resolve) => {
        (canvas as HTMLCanvasElement).toBlob(resolve, targetType, quality);
      });
    }

    if (!blob) throw new Error('Blob creation failed');

    // 创建压缩后的文件
    return new File([blob], file.name.replace(/\.\w+$/, '.jpg'), {
      type: targetType,
    });
  } catch (error) {
    console.log(error);
    throw new Error(`Image compression failed: ${error}`);
  }
}

export function calculateHash(file: File) {
  return new Promise((resolve) => {
    const worker = new Worker('/scripts/hash/index.js');
    worker.postMessage({ file });
    worker.onmessage = (e) => {
      if (e.data) {
        resolve(e.data);
      }
    };
  });
}
