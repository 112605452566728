import { MaterialSymbolsClose } from '@/assets/icons/comm/MaterialSymbolsClose';
import { SvgSpinnersBarsScale } from '@/assets/icons/layout/SvgSpinnersBarsScale';
import { toast } from '@/layouts/components/ToastMessage';
import HttpSetting from '@/setting/httpSetting';
import { convertImageToBase64 } from '@/utils/file';
import { Image } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useMount, useUpdate } from 'ahooks';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compressImageAlternative } from './util';

/**
 * 上传组件的属性类型定义。
 */
interface BaseUploadProps {
  onChange: any; // 上传成功后的回调函数
  currentUrls: string[] | string; // 当前已上传的单个文件 URL 或多文件 URL 数组
  children: ReactNode; // 默认展示的内容
  isMultiple?: boolean; // 是否是多文件上传
  preview?: ReactNode; // 自定义预览内容
  minHeight?: number | string; // 预览的最小高度
  onlyHeight?: number | string; // 针对单个文件的预览高度，多个文件没有必要
  isPreview?: boolean; // 是否需要显示预览，如果不需要，则会一直展示默认内容
  uploadApiUrl?: string; // 文件上传的目标 URL
  requestData?: any; // 发送到服务器的其他请求数据
  isCompressed?: any; // 是否压缩
  fileType?: string; //上传文件类型
  isBase64PreviewImg?: boolean; // 是否开启前端预览
}

/**
 * 该组件用于实现文件上传功能，支持单个文件或多个文件的上传 ---目前仅支持上传图片。
 *
 * @component
 * @param {Object} props - 传入的属性对象。
 * @param {Function} props.onChange - 上传成功后的回调函数，接收一个参数 `newImageUrl`，表示新上传文件的 URL。
 * @param {string[] | string} props.currentUrls - 当前已上传的单个文件 URL 或多文件 URL 数组。
 * @param {ReactNode} props.children - 默认展示的内容。
 * @param {boolean} [props.isMultiple=false] - 是否是多文件上传，默认为 `false`。
 * @param {ReactNode} [props.preview] - 自定义预览内容。
 * @param {number | string} [props.minHeight=140] - 预览的最小高度。
 * @param {number | string} [props.onlyHeight=140] - 针对单个文件的预览高度，多个文件没有必要。
 * @param {boolean} [props.isPreview=true] - 是否需要显示预览，如果不需要，则会一直展示默认内容。
 * @param {string} [props.uploadApiUrl] - 文件上传的目标 URL。
 * @param {any} [props.requestData] - 发送到服务器的其他请求数据。
 * @returns {JSX.Element} 返回一个用于文件上传的组件。
 */
const BaseUpload: React.FC<BaseUploadProps> = ({
                                                 isMultiple = false,
                                                 onChange,
                                                 children,
                                                 preview,
                                                 currentUrls,
                                                 minHeight = 140,
                                                 onlyHeight = 140,
                                                 isPreview = true,
                                                 uploadApiUrl = HttpSetting.UPLOAD_URL,
                                                 requestData = {},
                                                 isCompressed = true, //是否开启压缩代码，默认400k开始，false关闭
                                                 fileType = 'image',
                                                 isBase64PreviewImg = false, // 默认关闭base64预览
                                               }) => {
  const { t } = useTranslation();
  //文件上传表单
  const uploadRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  // 代理
  const uploadApiUrl_proxy = useMemo(() => {
    const storedApiUrl = localStorage.getItem('window.apiUrl');
    if (storedApiUrl) {
      return `${storedApiUrl}/app-api/infra/file/upload`;
    }
    return uploadApiUrl;
  }, [localStorage.getItem('window.apiUrl'), uploadApiUrl]);

  // 图片类型检查辅助函数
  const isImageFile = (file: File): boolean => {
    return /image\/\w*/.test(file.type);
  };

  //视频类型辅助函数
  const isVideoFile = (file: File): boolean => {
    return /^video\//.test(file.type);
  };

  const { user } = useModel('user');

  const { socketId } = useModel('socket');
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = socketId;
  }, [socketId]);

  // let token = '';
  // try {
  //   const localToken = localStorage.getItem(CacheEnum.AUTHORIZATION);
  //   token =
  //     localToken && localToken !== 'undefined' ? toJsonData(localToken) : '';
  // } finally {
  // }

  //单个文件上上传
  const handleOnlyFileUpload = async (file: File) => {
    if (file) {
      //检查图片
      if (fileType === 'image') {
        if (!isImageFile(file)) {
          return toast.error(t('请选择一个有效的图片文件!'));
        }
      }
      //检查视频
      if (fileType === 'video') {
        if (!isVideoFile(file)) {
          return toast.error(t('请选择一个有效的视频文件!'));
        }
      }

      if (file.size === 0) {
        // 文件大小为 0
        throw new Error(t('文件上传失败!'));
      }

      try {
        const formData = new FormData();
        let reqFile: any = file;
        const { size: FileSize } = file;
        // 如果是图片并且开启压缩才执行压缩
        if (FileSize > 800 * 1024 && isCompressed && fileType === 'image') {
          console.log('大于400kb,执行压缩图片');
          reqFile = await compressImageAlternative(file);
        }
        if (isBase64PreviewImg) {
          const base64String = await convertImageToBase64(reqFile);
          onChange?.(base64String as any, 'isBase64PreviewImg');
        }

        formData.append('file', reqFile);
        // 遍历数组，将每个元素的 key 和 value 添加到 FormData
        Object.keys(requestData)?.forEach((key) => {
          formData.append(key, requestData[key]);
        });

        const response = await fetch(uploadApiUrl_proxy, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${socketRef.current}`, // 添加 Authorization 头
            userId: user?.id,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.code !== 0) return toast.error(t('文件上传失败!'));
          const newImageUrl = data.data;
          onChange?.(newImageUrl);
        } else {
          const errorData = await response.json();
          return toast.error(errorData);
        }
      } catch (error) {
        console.log(error);
        return toast.error(t('上传文件时发生错误!'));
      } finally {
        setLoading(false);
      }
    }
  };
  //多个文件上传
  const handleMultipleFileUpload = async (files: FileList) => {
    try {
      const uploadPromises = Array.from(files).map(async (file) => {
        if (fileType === 'image') {
          if (!isImageFile(file)) {
            throw new Error(t('请选择一个有效的图片文件!'));
          }
        }
        if (fileType === 'video') {
          if (!isImageFile(file)) {
            throw new Error(t('请选择一个有效的视频文件!'));
          }
        }
        const formData = new FormData();
        let reqFile: any = file;
        const { size: FileSize } = file;
        if (FileSize > 800 * 1024 && isCompressed && fileType === 'image') {
          console.log('大于800kb,执行压缩图片');
          reqFile = await compressImageAlternative(file);
        }
        formData.append('file', reqFile);
        // 遍历数组，将每个元素的 key 和 value 添加到 FormData
        Object.keys(requestData)?.forEach((key) => {
          formData.append(key, requestData[key]);
        });
        const response = await fetch(uploadApiUrl_proxy, {
          method: 'POST',
          headers:{
            Authorization: `Bearer ${socketRef.current}`, // 添加 Authorization 头
            userId: user?.id,
          },
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          if (data.code !== 0) throw new Error(t('文件上传失败!'));
          uploadRef.current.value = '';
          return data.data;
        } else {
          const errorData = await response.json();
          throw new Error(errorData);
        }
      });
      if (uploadPromises.length) {
        const uploadedFiles = await Promise.all(uploadPromises);
        uploadRef.current.value = '';
        requestAnimationFrame(() => {
          onChange?.((prevState: any) => {
            return [...prevState, ...uploadedFiles];
          });
        });
      }
    } catch (error: any) {
      toast.error(error.message || t('上传文件时发生错误!'));
    } finally {
      setLoading(false);
    }
  };
  //点击文件上传响应事件
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (fileType === 'video') {
      onChange?.(event.target.files?.[0] as any);
      return;
    }
    setLoading(true);

    //多个文件上传
    if (isMultiple) {
      handleMultipleFileUpload(event.target.files as FileList);
    } else {
      // 单个文件
      handleOnlyFileUpload(event.target.files?.[0] as File);
    }
  };

  //多文件删除
  const handleDeleteFile = (index: number) => {
    if (isMultiple) {
      onChange?.((prevState: any[]) => {
        return prevState?.filter((_, i) => i !== index);
      });
    } else {
      uploadRef.current.value = '';
      onChange?.('');
      // 重置上传表单元素
    }
  };

  useMount(() => {
    uploadRef.current?.addEventListener('change', handleFileChange as any);

    return {
      unmount: () => {
        uploadRef.current?.removeEventListener(
          'change',
          handleFileChange as any,
        );
      },
    };
  });

  return (
    <>
      <div
        className="w-full flex  justify-center relative"
        style={{ minHeight: minHeight }}
        onClick={(event) => {
          setTimeout(() => {
            uploadRef.current?.click();
            event.stopPropagation();
          });
        }}
      >
        {/*初始化显示内容------如果不需要预览效果，则应当展示默认内容*/}
        {(!currentUrls || currentUrls?.length === 0 || !isPreview) &&
          !loading &&
          children}
        {loading && (
          <div
            className="h-[100%] w-full text-xs bg-backgroundAuxiliaryColor text-auxiliaryTextColor flex flex-col  items-center justify-center"
            style={{ minHeight: minHeight }}
          >
            <SvgSpinnersBarsScale className="text-2xl text-primary" />
            <span className="mt-2">{t('上传中')}</span>
          </div>
        )}

        {/*文件预览内容，使用者自己diy*/}
        {currentUrls?.length > 0 && isPreview && (
          <div
            className="relative w-full z-10"
            style={{ minHeight: minHeight }}
          >
            {/*多文件默认预览*/}
            {!preview && isMultiple && (
              <div
                className="bg-backgroundAuxiliaryColor py-4 px-4"
                style={{ minHeight: minHeight }}
              >
                {
                  <div className="grid grid-cols-3 gap-[20px]">
                    {(currentUrls as string[])?.map((item, index) => {
                      return (
                        <div
                          key={item + index}
                          className="relative group/item w-full"
                        >
                          <Image
                            classNames={{
                              wrapper: '!max-w-[100%]',
                            }}
                            className="w-full h-[60px] rounded-md cursor-pointer object-cover"
                            src={item}
                          />
                          {/*删除按钮*/}
                          <MaterialSymbolsClose
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteFile(index);
                            }}
                            width="1.2em"
                            height="1.2em"
                            className="absolute z-50   cursor-pointer   items-center
                              top-0 right-0  bg-backgroundAuxiliaryColor"
                          />
                        </div>
                      );
                    })}
                  </div>
                }
              </div>
            )}
            {/*单文件默认预览*/}
            {!preview && !isMultiple && (
              <div className="w-full">
                <div
                  className="bg-backgroundAuxiliaryColor w-full "
                  style={{ height: onlyHeight }}
                >
                  <div
                    className="relative w-full  pr-14   py-4 px-4"
                    style={{ height: onlyHeight }}
                  >
                    <Image
                      classNames={{
                        wrapper: `!max-w-[100%] !h-[100%]`,
                      }}
                      className="w-full h-[100%] rounded-md cursor-pointer object-cover"
                      src={currentUrls as string}
                    />
                    {/*删除按钮*/}
                    <MaterialSymbolsClose
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange?.('');
                      }}
                      width="1.6em"
                      height="1.6em"
                      className="absolute z-50  cursor-pointer items-center
                              top-1/2 -translate-y-1/2 right-2  bg-backgroundAuxiliaryColor"
                    />
                  </div>
                </div>
              </div>
            )}
            {preview}
          </div>
        )}
      </div>
      <input
        ref={uploadRef}
        multiple={isMultiple}
        // onChange={handleFileChange}
        type="file"
        className="hidden"
        accept={`${fileType}/*`}
      />
    </>
  );
};

export default React.memo(BaseUpload);
