export function convertImageToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function base64ToFile(base64String: string, fileName: string): File {
  if (!base64String) {
    throw new Error('Invalid base64 string');
  }

  const parts = base64String.split(',');

  if (parts.length !== 2) {
    throw new Error('Invalid base64 format. Expected "data:[mime];base64,[data]"');
  }

  const mimeTypeMatch = parts[0].match(/:(.*?);/);
  if (!mimeTypeMatch) {
    throw new Error('Invalid MIME type in base64 string');
  }

  const mimeType = mimeTypeMatch[1]; // 获取 MIME 类型，如 'image/jpeg'
  const byteString = atob(parts[1]); // 解码 Base64 数据
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  // 将解码后的字符转换为字节
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  // 创建并返回 File 对象
  return new File([uint8Array], fileName, { type: mimeType });
}
